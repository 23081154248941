import React, { Component } from 'react';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import {convertToMoney} from  '../utils/utils';
import config from '../config.json';

class SectionItemShop extends Component {

    constructor(){
        super();
        this.state = {
            src: './images/default.jpg'
        }
    }
    
    componentWillMount() {
        this.onLoadImage()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.code !== this.props.code) {
            this.setState({src: './images/default.jpg'})
            this.onLoadImage()
        }
    }
    
    onLoadImage = () => {
        let src =  `${config.GOOGLE_STORAGE}/${config.FIREBASE_STORAGE_BUCKET}/${this.props.type === 1 ? "products" : "accessories"}/${this.props.code}/thumbnail`
        this.setState({src: src})
    }
    
    formatPriceWithoutDot = (price) => {
        var newPrice = price.toString().replace(".", "");
        return newPrice;
    }

    onClickCart = () => {
        if (this.props.stock === 0) {
            return;
        }

        var json = localStorage.getItem("cartItems")
        var list = []
        if (json !== undefined && json !== null) {
            list = JSON.parse(json);
        }
        var stockMaximumReached = false
        var index = -1;
        for (var i=0; i<list.length; i++) {
            if (list[i].code === this.props.code) {
                index = i;
                if (list[i].quantity < this.props.stock && list[i].quantity < 3)
                    list[i].quantity += 1; 
                else
                    stockMaximumReached = true
            }
        }

        if (stockMaximumReached)
            NotificationManager.error('No hay suficiente stock para agregar este juego a tu carrito.', 'Stock máximo alcanzado');        
        else
        {
            if (index === -1) {
                list.push({
                    prefix: this.props.prefix,
                    code: this.props.code,
                    name: this.props.name,
                    price: this.formatPriceWithoutDot(this.props.price),
                    quantity: 1,
                    type: this.props.type,
                    discount: this.props.discount,
                    promo3x2: this.props.promo3x2
                });
            }
    
            var dataJSON = JSON.stringify(list);
            localStorage.setItem("cartItems", dataJSON);
    
            this.props.onAddToCart();
            NotificationManager.success('Estás un paso mas cerca de tu proxima partida 😎', 'Agregado al carrito');
        }       
    }

    getPrice = () => {
        let originalPrice = this.props.price;
        let discount = this.props.discount;
        
        let oldPrice = ""
        if (discount !== 0) {
            oldPrice = <span className= "oldPrice">${convertToMoney(this.props.price)}</span>
            originalPrice = originalPrice * (1 - discount/100)
        }
        return(
        <div className="col-9" style={{marginBottom: '10px'}}>
            <span className= "newPrice">$ {convertToMoney(originalPrice)}</span>
            {oldPrice}
        </div>)
    }
    
    getLabel = () => {
        let discount = this.props.discount;
        if (this.props.stock === 0)
            return <img alt="" src="images/agotado-sello.png" style={{display:'block', position: 'absolute', width: '90px', zIndex: '50'}}/>
        else{
            if (discount > 0)
            {
                if ((discount === 10 || discount === 15 || discount === 20 || discount === 25 || discount === 30 || discount === 35 || discount === 40))
                {
                    let saleImage = `images/sale-${this.props.discount}.png`
                    return <img alt="" src={saleImage} style={{display:'block', position: 'absolute', width: '170px', zIndex: '50'}}/>
                }
                else
                    return <img alt="" src="images/sale.png" style={{display:'block', position: 'absolute', width: '170px', zIndex: '50'}}/>
            }               
        }
    }
    
    render() {
        return (            
            <div className={`${this.props.classQuantity}`}  style={{marginBottom: '15px', minHeight: "325px"}}>
                { this.getLabel() }
                <div className="grey-section">
                    <div onClick={()=>window.location.href=`/item/${this.props.path}`} style={{position: 'relative'}} className="topCard" >
                        <img alt="" src={this.state.src} onError={(e) => {e.target.onerror = null; e.target.src='./images/default.jpg'}} style={{padding: '7%', minWidth: '100%', minHeight: "170px"}}/>
                        <h6 style={{marginBottom: '20px', fontFamily: 'Lato, sans-serif', fontSize: '13px', textTransform: 'uppercase', color: '#111111', marginLeft:'12px', marginRight:'12px'}}>{this.props.name}</h6>
                        <hr style={{margin: 0, border: '1px solid #e0e0e0'}}/>
                        <img alt="" src="images/promo3x2.png" className="promo3x2"style={{display: this.props.promo3x2 ? 'block': 'none'}}/>
                    </div>
                    <div className="row" style={{paddingTop: '15px', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px'}}>
                        { this.getPrice() }
                        <div className="col-3" style={{display:this.props.stock===0?'none':'block', fontSize: '18px', textAlign: 'right', paddingRight: '25px', paddingLeft: '0'}}>
                            <i data-tip="Agregar al carrito" className="item-cart fa fa-fw" onClick={() => this.onClickCart()}></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionItemShop;